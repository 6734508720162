<template>
  <div>
    <div>
      <h2 class="d-inline margin-top-xlg">Import Requests</h2>
      <a class="button-link float-right" href="/tools/import">New</a>
    </div>

    <el-table
        stripe
        :data="requests"
        style="width: 100%">
      <el-table-column
          prop="user.name"
          label="User"
          width="170"
      >
      </el-table-column>
      <el-table-column
          v-if="role === 'Super Admin'"
          prop="organization.name"
          label="Organization"
          width="230"
      >
      </el-table-column>
      <el-table-column
          prop="status_text"
          label="Status"
          width="90">
      </el-table-column>
      <el-table-column
          sortable
          prop="created_at"
          label="Created"
          width="230">
      </el-table-column>

      <el-table-column
          fixed="right"
          width="320"
          label="Actions"
          class-name="column-actions">
        <template slot-scope="scope">
          <el-button size="mini" @click.native.prevent="download(scope.row.import_id)">
            <i class="ti-download margin-right-xsm"></i>Download
          </el-button>
          <el-button v-if="role === 'Super Admin' && scope.row.status == 1" size="mini" @click.native.prevent="view(scope.row.import_id)">
            <i class="ti-control-play margin-right-xsm"></i>Continue
          </el-button>
          <el-button v-if="scope.row.status == 1" :loading="isDeleting == scope.row.id" size="mini"
                     @click.native.prevent="cancel(scope.$index, scope.row.id)">Cancel
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDeleting: null
    }
  },
  props: {
    requests: Array,
    role: String
  },
  methods: {
    catchError(error) {
      this.isDeleting = -1;

      if (error.response || error.request) {
        this.$message.error("There was a server error.");
      } else {
        this.$message.error(error.message);
      }
    },
    cancel(index, id) {
      this.$confirm('This will cancel the Lease import. Continue?', 'Are you sure?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        center: true
      }).then(() => {
        this.isDeleting = index;

        axios.post('/tools/import-requests/' + id, {action: 'cancel'})
            .then(function (response) {
              if (!_.has(response, 'data.success') || response.data.success !== true) {
                throw new Error(response.data.error);
              }

              this.isDeleting = null;
              this.$message('Import Request canceled!');
              this.requests[index].status = 0;
              this.requests[index].status_text = 'Canceled';
            }.bind(this))
            .catch(this.catchError.bind(this));
      });
    },
    download(id){
      window.open('/tools/export-import-file/' + id, '_blank');
    },
    view(id){
      window.location.href = '/tools/import/' +id
    }
  }
}
</script>
