var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { stripe: "", data: _vm.requests },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "user.name", label: "User", width: "170" },
          }),
          _vm._v(" "),
          _vm.role === "Super Admin"
            ? _c("el-table-column", {
                attrs: {
                  prop: "organization.name",
                  label: "Organization",
                  width: "230",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status_text", label: "Status", width: "90" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "created_at",
              label: "Created",
              width: "230",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              width: "320",
              label: "Actions",
              "class-name": "column-actions",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.download(scope.row.import_id)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "ti-download margin-right-xsm",
                        }),
                        _vm._v("Download\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.role === "Super Admin" && scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.view(scope.row.import_id)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "ti-control-play margin-right-xsm",
                            }),
                            _vm._v("Continue\n        "),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.isDeleting == scope.row.id,
                              size: "mini",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancel(scope.$index, scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Cancel\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "d-inline margin-top-xlg" }, [
        _vm._v("Import Requests"),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button-link float-right",
          attrs: { href: "/tools/import" },
        },
        [_vm._v("New")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }