/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Vue from "vue";
window.Vue = Vue;
window.moment = require('moment');
var ElementUi = require('element-ui');
import locale from 'element-ui/lib/locale';
Vue.config.devtools = true;

require('es6-shim');
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();

/**
 * Converts a snake case string to title case.
 * Example: snake_case => Snake Case
 *
 * @param  {String} str the string to convert
 * @return {String}
 */
window.Vue.filter('snakeToTitle', function (str) {
  return str.split('_').map(function (item) {
    return item.charAt(0).toUpperCase() + item.substring(1);
  }).join(' ');
});

window.timestampToDate = function (timestamp) {
  return window.moment.unix(timestamp).format("MMMM D, YYYY");
};

locale.use({
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear'
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      // week: 'week',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Loading',
      noMatch: 'No matching data',
      noData: 'No data',
      placeholder: 'Select'
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select'
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/pg.',
      total: '{total} total results',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input'
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue'
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum'
    },
    tree: {
      emptyText: 'No Data'
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'], // to be translated
      filterPlaceholder: 'Enter keyword', // to be translated
      noCheckedFormat: '{total} items', // to be translated
      hasCheckedFormat: '{checked}/{total} checked' // to be translated
    }
  }
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/**
 * Import all Vue components from ./components folder and its subfolders
 * in one object and name them by FileName => file->name
 */
const ComponentContext = require.context('./', true, /\.vue$/i, 'lazy');

ComponentContext.keys().forEach((filePath) => {
  const componentName = filePath.split('/').pop().split('.')[0];
  Vue.component(componentName, () => ComponentContext(filePath));
});

// PAGES - Webpack 5 requires import as demonstrated in below two lines
import ImportRequests from './pages/ImportRequests';
Vue.component('import-requests', ImportRequests);

window.addEventListener('load', function () {
  const app = new Vue({
    el: '#app',
    methods: {
      formatDate(format, timestamp) {
        return moment.unix(timestamp).format(format);
      }
    },
    mounted() {
      let message = this.$el.attributes.message.value;
      if (message) {
        this.$message({
          message,
          type: 'message'
        });
      }
    }
  });
});